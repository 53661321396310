@font-face {
  font-family: 'Open Sans';
  src: url('../../public/assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf');
  src: url('../../public/assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
  font-style: normal;
}

body{
  font-family: 'Open Sans'
}

/* width */
::-webkit-scrollbar {
  background-color: #aaaaaa61;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background-color: #aaaaaa61;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa61;
  width: 10px;
}

::-webkit-scrollbar:horizontal{
  height: 6px;
  background-color: #aaaaaa61;
}

.swal2-container {
  z-index: 1400 !important;
}

.Tree-Item {
  /* min-width: 50vh !important; */
  /* position: relative !important; */
  border-left: 1px dashed !important;
}

.Tree-Item-Selected>div {
  background-color: rgb(224, 192, 255) !important;
}


.Tree-Item>div:active {
  background-color: rgb(224, 192, 255) !important;
  opacity: 0.8;
}


.Tree-Item-Label {
  /* margin-left: 1vh !important; */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: flex;
}
.bg-gradient-purple {
  background-image: linear-gradient(310deg, #7e22ce , #ec4899 );
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bg-gradient-cyan {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gradient-orange {
  background-image: linear-gradient(310deg, #f53939, #fbcf33);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gradient-red {
  background-image: linear-gradient(310deg, #ea0606, #ff667c);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gradient-lime {
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gradient-blue {
  background-image: linear-gradient(310deg, #1d4ed8, #93c5fd);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gradient-stone{
  background-image: linear-gradient(310deg, #44403c, #a8a29e);
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.autocomplete {
  position: relative;
  /* Tùy chỉnh theo nhu cầu */
}

.autocomplete-input {
  padding: 0.5rem 1rem;

  /* border: 1px solid #d1d5db; */
  /* border-radius: 0.25rem; */
  /* Tùy chỉnh theo nhu cầu */
}

.autocomplete-list {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  max-height: 200px;
  /* Tùy chỉnh theo nhu cầu */
  overflow-y: auto;
}

.autocomplete-list-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.autocomplete-list-item:hover {
  background-color: #f3f4f6;
}
